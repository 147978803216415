import { usePagination } from '@ajna/pagination';
import { Box, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { Loader, PaginationWrapper } from 'components';
import { useState } from 'react';
import { useFetchReferralsByUserIdQuery } from 'store/services/request.service';
import { formatDay, formatTimeToUTC } from 'utils/formatDateToUTC';

const UserReferrals = ({ userId }) => {
  const limit = 10;
  const [page, setPage] = useState(1);
  const { data, isLoading } = useFetchReferralsByUserIdQuery({
    userId,
    limit,
    page,
  });

  const { currentPage, setCurrentPage, pagesCount, pages, isDisabled, pageSize, offset } = usePagination({
    initialState: {
      currentPage: 1,
      pageSize: limit,
    },
    total: data?.totalCount,
    limits: {
      inner: 2,
      outer: 1,
    },
  });

  return (
    <Box>
      {isLoading && <Loader />}

      {!isLoading && data?.referrals?.length === 0 && (
        <Text
          fontSize='18px'
          fontWeight='500'
        >
          User has no referrals
        </Text>
      )}

      {data?.referrals?.length > 0 && (
        <Table mt='2rem'>
          <Thead>
            <Tr>
              <Th>Referred User</Th>
              <Th>Registered On</Th>
              <Th>KYC Level</Th>
              <Th>Trade Counts</Th>
              <Th>Last Earnings</Th>
              <Th>Total Earnings</Th>
            </Tr>
          </Thead>

          <Tbody>
            {data &&
              data?.referrals?.map(
                ({ _id, referredUser, registeredOn, combinedEarned, lastEarned, tradeCounts }, i) => {
                  return (
                    <Tr key={_id}>
                      <Td
                        fontWeight='500'
                        textTransform='capitalize'
                      >
                        <a
                          target='_blank'
                          href={`/dashboard/users/${referredUser?._id}`}
                          rel='noreferrer'
                        >
                          {referredUser?.firstName} {referredUser?.lastName}
                        </a>
                      </Td>

                      <Td>
                        {formatDay(registeredOn)}, {formatTimeToUTC(registeredOn)}
                      </Td>

                      <Td textTransform='capitalize'>{referredUser?.kycLevel}</Td>

                      <Td>{tradeCounts}</Td>

                      <Td textTransform='uppercase'>
                        {lastEarned?.value}{' '}
                        <Text
                          as='span'
                          fontWeight='500'
                        >
                          {lastEarned?.unit}
                        </Text>
                      </Td>

                      <Td>
                        {combinedEarned &&
                          Object.entries(combinedEarned)?.map(([key, value], index) => {
                            console.log(key, value, 'KEY VALUE');
                            return (
                              <Text
                                key={index}
                                textTransform='uppercase'
                              >
                                {value}{' '}
                                <Text
                                  as='span'
                                  fontWeight='500'
                                >
                                  {key}
                                </Text>
                              </Text>
                            );
                          })}
                      </Td>
                    </Tr>
                  );
                }
              )}
          </Tbody>
        </Table>
      )}

      {data?.totalCount > 10 && (
        <PaginationWrapper
          setCurrentPage={setCurrentPage}
          totalDataCount={isLoading ? 0 : data?.totalCount}
          currentPage={currentPage}
          setPage={setPage}
          isDisabled={isDisabled}
          pagesCount={pagesCount}
          size={pageSize}
          offset={offset}
          pages={pages}
        />
      )}
    </Box>
  );
};

export default UserReferrals;
