import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Text, Table, Thead, Tr, Th, Tbody, Td, Box, Flex } from '@chakra-ui/react';
import { usePagination } from '@ajna/pagination';
import { FilterSelect, Loader, PaginationWrapper, ScrollRightContainer, TableDataStatus } from 'components';
import { fetchUserTransaction } from 'store/slices/wallet.slice';
import { formatDateToUTC } from 'utils';
import { useCopy } from 'hooks';
import { CheckIcon, CopyIcon } from '@chakra-ui/icons';
import { supportedCoins } from 'constant';
import { formatValuePerUnit } from 'utils';
import DownloadUserTransactions from './DownloadUserTransactions';

const UserTransactions = ({ userId }) => {
  const dispatch = useDispatch();
  const { data, status } = useSelector((state) => state.wallet.userTranasction);

  console.log(data, 'THE DATA');
  const [limit, setLimit] = useState(20);
  const [unit, setUnit] = useState('');
  const [type, setType] = useState('');
  const [customType, setCustomType] = useState();

  const { currentPage, setCurrentPage, pagesCount, pages, isDisabled, offset } = usePagination({
    initialState: {
      currentPage: 1,
      pageSize: limit,
    },
    total: data.totalCount,
    limits: {
      inner: 2,
      outer: 1,
    },
  });

  useEffect(() => {
    dispatch(
      fetchUserTransaction({
        userId,
        limit,
        page: currentPage,
        unit,
        type,
        customType,
      })
    );
  }, [dispatch, userId, currentPage, limit, unit, type, customType]);

  const { hasCopied, onCopy, value } = useCopy();

  const handleLimit = (e) => {
    setCurrentPage(1);
    setLimit(e.target.value);
  };

  const handleCoin = (e) => {
    setCurrentPage(1);
    setUnit(e.target.value);
  };

  const handleType = (e) => {
    setCurrentPage(1);
    setType(e.target.value);
  };

  const handleCustomType = (e) => {
    setCurrentPage(1);
    setCustomType(e.target.value);
  };

  return (
    <>
      <Flex
        mb='24px'
        justifyContent='space-between'
      >
        <Flex gridGap='16px'>
          <FilterSelect onChange={handleLimit}>
            <option value={20}>Size</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </FilterSelect>
          <FilterSelect onChange={handleCoin}>
            <option value=''>Unit</option>

            {supportedCoins.map(({ coin, id }) => {
              return (
                <option
                  key={id}
                  value={coin}
                >
                  {coin.toUpperCase()}
                </option>
              );
            })}

            <option value='ngn'>NGN</option>
            <option value='kes'>KES</option>
            <option value='ghs'>GHS</option>
          </FilterSelect>

          <FilterSelect onChange={handleType}>
            <option value=''>Type</option>
            <option value='DEBIT'>Debit</option>
            <option value='CREDIT'>Credit</option>
          </FilterSelect>

          <FilterSelect onChange={handleCustomType}>
            <option value=''>All Event</option>
            <option value='swap'>Swap</option>
            <option value='buy'>Buy</option>
            <option value='sell'>Sell</option>
            <option value='p2p'>P2P</option>
            <option value='transfer'>Transfer</option>
            <option value='deposit'>Deposit</option>
            <option value='withdrawal'>Withdrawal</option>
            <option value='airtime'>Airtime</option>
            <option value='data'>Data</option>
            <option value='bills'>Bills</option>
            <option value='cards'>Cards</option>
            <option value='greenbox'>Greenbox</option>
            <option value='liens'>Liens</option>
            <option value='bcpl'>BCPL</option>
            <option value='rewards'>Rewards</option>
            <option value='staking'>Staking</option>
          </FilterSelect>
        </Flex>

        <DownloadUserTransactions
          userId={userId}
          download={data.totalCount > 0}
        />
      </Flex>

      {status === 'loading' && <Loader />}

      {status === 'fulfilled' && data.totalCount === 0 && (
        <Text
          fontSize='18px'
          fontWeight='500'
        >
          User has no transaction
        </Text>
      )}

      {(data && data.transactions).length >= 1 && (
        <>
          <ScrollRightContainer pos='relative'>
            <Table>
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>Date</Th>
                  <Th>Unit</Th>
                  <Th>Type</Th>
                  <Th>Amount</Th>
                  <Th>Balance Before</Th>
                  <Th>Balance After</Th>
                  <Th>Event name</Th>
                  <Th>Description</Th>
                  <Th>Recepient Address</Th>
                </Tr>
              </Thead>

              <Tbody fontSize={{ base: '12px', md: '14px  ' }}>
                {data.transactions.map(
                  (
                    {
                      _id,
                      unit,
                      transactionRef,
                      operation,
                      eventName,
                      balanceBefore,
                      balanceAfter,
                      createdAt,
                      description,
                      value: txValue,
                    },
                    i
                  ) => {
                    return (
                      <Tr key={_id}>
                        <Td>{i + 1}</Td>

                        <Td>{formatDateToUTC(createdAt)}</Td>

                        <Td textTransform='uppercase'>{unit}</Td>

                        <Td>
                          <TableDataStatus
                            status={String(operation).toLowerCase()}
                            textTransform='capitalize'
                          />
                        </Td>

                        <Td>
                          {txValue?.$numberDecimal ? formatValuePerUnit(Number(txValue?.$numberDecimal), unit) : 'N/A'}
                        </Td>

                        <Td>{balanceBefore ? formatValuePerUnit(balanceBefore, unit) : 'N/A'}</Td>

                        <Td>{balanceAfter ? formatValuePerUnit(balanceAfter, unit) : 'N/A'}</Td>

                        <Td textTransform='uppercase'>{eventName}</Td>

                        <Td>{description}</Td>

                        <Td>
                          {transactionRef?.receiveAddress ? (
                            <>
                              {transactionRef?.receiveAddress ? (
                                <Box
                                  cursor='pointer'
                                  onClick={() => onCopy(transactionRef.receiveAddress)}
                                >
                                  {transactionRef.receiveAddress} &nbsp;
                                  {value === transactionRef.receiveAddress && hasCopied ? <CheckIcon /> : <CopyIcon />}
                                </Box>
                              ) : (
                                ''
                              )}
                            </>
                          ) : (
                            ''
                          )}
                        </Td>
                      </Tr>
                    );
                  }
                )}
              </Tbody>
            </Table>
          </ScrollRightContainer>

          <PaginationWrapper
            setCurrentPage={setCurrentPage}
            totalDataCount={data.totalCount}
            currentPage={currentPage}
            isDisabled={isDisabled}
            pagesCount={pagesCount}
            size={limit}
            offset={offset}
            pages={pages}
          />
        </>
      )}
    </>
  );
};

export default UserTransactions;
