import { useState } from 'react';
import { CSVDownload } from 'react-csv';
import { Box } from '@chakra-ui/react';
import { Button } from 'components';
import { get } from 'utils/axiosLib';
import { getHeaders, wrapBaseApi } from 'utils';
import { BASE_URL } from 'constant';

const DownloadUserTransactions = ({ userId, download }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const handleExport = async () => {
    setLoading(true);

    try {
      const response = await get(
        wrapBaseApi(`${BASE_URL}/wallet/api/v2/admin/transactions/user/${userId}?download=true`),
        getHeaders()
      );

      setData(response.data);
      if (response) {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      {download && (
        <Box>
          {data.length > 0 && loading === false ? (
            <CSVDownload
              filename='bitmama-transactions.csv'
              data={data}
            />
          ) : (
            ''
          )}

          <Button
            minH='42px'
            onClick={handleExport}
            disabled={loading}
            isLoading={loading}
            fontWeight='500'
          >
            Download Transactions
          </Button>
        </Box>
      )}
    </>
  );
};

export default DownloadUserTransactions;
