import { BsTools } from 'react-icons/bs';
import { MdShortcut } from 'react-icons/md';
import { FcDataEncryption, FcUnlock } from 'react-icons/fc';

export const UserLinkArray = [
  {
    _id: 1,
    searchValue: '',
    title: 'Information',
    defaultLink: '/dashboard/users',
  },
  {
    _id: 12,
    searchValue: '?withdrawal',
    title: 'Withdrawal',
    defaultLink: '/dashboard/users',
  },
  {
    _id: 231,
    searchValue: '?deposit',
    title: 'Deposit',
    defaultLink: '/dashboard/users',
  },
  {
    _id: 4231,
    searchValue: '?wallet',
    title: 'Wallet',
    defaultLink: '/dashboard/users',
  },
  {
    _id: 12331,
    searchValue: '?transactions',
    title: 'Transactions',
    defaultLink: '/dashboard/users',
  },
  {
    _id: 1432,
    searchValue: '?cards',
    title: 'Cards',
    defaultLink: '/dashboard/users',
  },
  {
    _id: 14322,
    searchValue: '?referrals',
    title: 'Referrals',
    defaultLink: '/dashboard/users',
  },
  {
    _id: 15321,
    searchValue: '?lien',
    title: 'View Liens',
    defaultLink: '/dashboard/users',
  },
  // {
  //   _id: nanoid(),
  //   searchValue: '?user-notes',
  //   title: 'User Notes',
  //   defaultLink: '/dashboard/users',
  // },
];

export const WalletTabLinkArray = [
  {
    id: 1,
    search: '',
    title: 'Deposit',
    to: '/dashboard/inflow-outflow',
    otherSearchValue: '?type=crypto',
  },
  {
    id: 2,
    search: '?withdrawal',
    title: 'Withdrawal',
    to: '/dashboard/inflow-outflow?withdrawal',
  },
  {
    id: 3,
    search: '?batch-payment',
    title: 'Batch Payment',
    to: '/dashboard/inflow-outflow?batch-payment',
  },
];

export const AirtimeDataTabLinkArray = [
  {
    id: 1,
    search: '',
    title: 'Data',
    to: '/dashboard/data-airtime',
  },
  {
    id: 2,
    search: '?airtime',
    title: 'Airtime',
    to: '/dashboard/data-airtime?airtime',
  },
];

export const CardTabLinkArray = [
  {
    id: 1,
    search: '',
    title: 'Transactions',
    to: '/dashboard/cards',
  },
  {
    id: 2,
    search: '?transactions-limit',
    title: 'Transactions limit',
    to: '/dashboard/cards?transactions-limit',
  },
  {
    id: 3,
    search: '?physical-cards',
    title: 'Physical Cards',
    to: '/dashboard/cards?physical-cards',
  },
];

export const BcplTabLinkArray = [
  {
    id: 1,
    search: '',
    title: 'Requests',
    to: '/dashboard/bcpl',
  },
  {
    id: 2,
    search: '?all-transactions',
    title: 'All Transactions',
    to: '/dashboard/bcpl?all-transactions',
  },
  {
    id: 3,
    search: '?defaulted-transactions',
    title: 'Defaulted Transactions',
    to: '/dashboard/bcpl?defaulted-transactions',
  },
];

export const QrCodeTabLinkArray = [
  {
    id: 1,
    search: '',
    title: 'QR Code',
    to: '/dashboard/qrcode',
  },
  {
    id: 1,
    search: '?claimed-rewards',
    title: 'Claimed Rewards',
    to: '/dashboard/qrcode?claimed-rewards',
  },
];

export const VerificationTabLinkArray = [
  {
    id: 1,
    search: '?pending',
    otherSearchValue: '',
    title: 'Pending',
    to: '/dashboard/verification?pending',
  },
  {
    id: 2,
    search: '?approved',
    title: 'Approved',
    to: '/dashboard/verification?approved',
  },
  {
    id: 3,
    search: '?denied',
    title: 'Rejected',
    to: '/dashboard/verification?denied',
  },
];

export const P2pTabLinkArray = [
  {
    id: 1,
    search: '?completed',
    otherSearchValue: '',
    title: 'Completed',
    to: '/dashboard/p2p?completed',
  },
  {
    id: 2,
    search: '?pending',

    title: 'Pending',
    to: '/dashboard/p2p?pending',
  },
  {
    id: 3,
    search: '?cancelled',
    title: 'Cancelled',
    to: '/dashboard/p2p?cancelled',
  },
];

export const P2pTradeTabLinkArray = [
  {
    id: 1,
    search: '?completed',
    otherSearchValue: '',
    title: 'Completed',
    to: '/dashboard/p2p/trades?completed',
  },
  {
    id: 2,
    search: '?pending',

    title: 'Pending',
    to: '/dashboard/p2p/trades?pending',
  },
  {
    id: 3,
    search: '?cancelled',
    title: 'Cancelled',
    to: '/dashboard/p2p/trades?cancelled',
  },
];

export const PartnersTabArray = [
  {
    id: 1,
    search: '?partners',
    otherSearchValue: '',
    title: 'Partners',
    to: '/dashboard/partners',
  },
  {
    id: 2,
    search: '?leaderboard',
    title: 'Leaderboard',
    to: '/dashboard/partners?leaderboard',
  },
];

export const CICOTabLinkArray = [
  {
    id: 1,
    search: '',
    title: 'Auth',
    to: '/dashboard/cico',
  },
  {
    id: 2,
    search: '?kyc',
    title: 'KYC',
    to: '/dashboard/cico?kyc',
  },
  {
    id: 3,
    search: '?transfer',
    title: 'Transfer',
    to: '/dashboard/cico?transfer',
  },
];

export const DisburseTabLinkArray = [
  {
    id: 1,
    search: '',
    title: 'Create Disbursements',
    to: '/dashboard/disbursements',
  },
  {
    id: 2,
    search: '?all',
    title: 'All Disbursements',
    to: '/dashboard/disbursements?all',
  },
];

export const SettingsTabLinkArray = [
  {
    id: 1,
    search: '',
    title: 'Rate',
    to: '/dashboard/settings',
  },
  {
    id: 2,
    search: '?request',
    title: 'Request',
    to: '/dashboard/settings?request',
  },
  {
    id: 3,
    search: '?config',
    title: 'App Config',
    to: '/dashboard/settings?config',
  },
  {
    id: 4,
    search: '?country-config',
    title: 'Country Config',
    to: '/dashboard/settings?country-config',
  },
];

export const IntegrationsTabLinkArray = [
  {
    id: 1,
    search: '',
    title: 'Transfer',
    to: '/dashboard/integrations',
  },
  {
    id: 2,
    search: '?kyc',
    title: 'KYC',
    to: '/dashboard/integrations?kyc',
  },
  {
    id: 3,
    search: '?users',
    title: 'Users',
    to: '/dashboard/integrations?users',
  },
];

export const UsersTabArray = [
  {
    id: 1,
    search: '',
    title: 'All Users',
    to: '/dashboard/users',
  },
  {
    id: 2,
    search: '?merchants',
    title: 'Merchants',
    to: '/dashboard/users?merchants',
  },
];

export const WishlistTabLinkArray = [
  {
    id: 1,
    search: '',
    title: 'Wishlist Campaign',
    to: '/dashboard/wishlist',
    otherSearchValue: '',
  },
  {
    id: 2,
    search: '?tag',
    title: 'Tag',
    to: '/dashboard/wishlist?tag',
  },
];

export const GamingTabArray = [
  {
    id: 1,
    search: '',
    title: 'Active',
    to: '/dashboard/gaming',
  },
  {
    id: 2,
    search: '?inactive',
    title: 'Inactive',
    to: '/dashboard/gaming?inactive',
  },
  // {
  //   id: 3,
  //   search: '?scheduled',
  //   title: 'Scheduled',
  //   to: '/dashboard/gaming?scheduled',
  // },
];

export const ToolsTabArray = [
  {
    id: 1,
    search: '',
    title: 'File Upload',
    to: '/dashboard/tools',
    icon: (
      <BsTools
        color='#fff'
        fontSize='48px'
      />
    ),
  },
  {
    id: 2,
    search: '?shortcuts',
    title: 'Shortcuts',
    to: '/dashboard/tools?shortcuts',
    icon: (
      <MdShortcut
        color='#0ff'
        fontSize='48px'
      />
    ),
  },
  {
    id: 3,
    search: '?encrypt',
    title: 'Encrypt Text',
    to: '/dashboard/tools',
    isDisabled: true,
    icon: <FcDataEncryption fontSize='48px' />,
  },
  {
    id: 4,
    search: '?decrypt',
    title: 'Decrypt Text',
    to: '/dashboard/tools',
    isDisabled: true,
    icon: <FcUnlock fontSize='48px' />,
  },
  // {
  //   id: 4,
  //   search: '?batch',
  //   title: 'Batch',
  //   to: '/dashboard/tools',
  //   isDisabled: true,
  //   icon: <FcWorkflow fontSize='48px' />,
  // },
];

export const commandsShortcuts = [
  {
    title: 'General Commands',
    controls: [
      {
        command: '/ /',
        description: 'Show Shortcuts',
      },
      {
        command: 'e e e',
        description: 'Enable Shortcut',
      },
      {
        command: 'd d d',
        description: 'Disable Shortcut',
      },
      {
        command: 'CLS',
        description: 'Clear Storage Token',
      },
      {
        command: 'Right Arrow',
        description: 'Go Forward',
      },
      {
        command: 'Back Arrow',
        description: 'Go to previous page',
      },
      {
        command: 'CTK',
        description: 'Copy token',
      },
    ],
  },
  {
    title: 'Transactions',
    controls: [
      {
        command: 'ut',
        description: 'Show user transactions',
      },
    ],
  },
  {
    title: 'Verification Command',
    controls: [
      {
        command: 'p',
        description: 'Approve KYC Modal',
      },
      {
        command: 'x',
        description: 'Reject KYC',
      },
      {
        command: 'r',
        description: 'Review KYC',
      },
    ],
  },
  {
    title: 'App Config',
    controls: [
      {
        command: 'apc',
        description: 'App Config',
      },
    ],
  },
];
